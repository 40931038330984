import React from 'react';
import { useTranslation } from 'react-i18next';
import { useAbsenceAPI } from '../contexts/AbsencesContext';
import moment from 'moment';

const AbsenceCard = ({ request, size }) => {
    const { t } = useTranslation();
    const { setStateRequestForm, getAbsenceDetails } = useAbsenceAPI()
    let mainCategory = request.time_off_main_category;
    !mainCategory && (mainCategory = "uncategorized");

    const formatLocalDate = (date_string) => moment(date_string).format(window.screen.width > 600 ? 'dddd, DD. MMM' : 'ddd, DD. MMM');

    const openDetailView = () => {
        getAbsenceDetails(request.id);
        setStateRequestForm({ display: true, view: "read", id: request.id });
    }

    const categoryLogo = (mainCategory) => (
        mainCategory === 'time_off' ? <img src={process.env.PUBLIC_URL + "/images/icon-holiday.png"} alt="" height="40" width="40" />
            : mainCategory === 'sick_leave' ? <img src={process.env.PUBLIC_URL + "/images/icon-sick.png"} alt="" height="40" width="40" />
                : <img src={process.env.PUBLIC_URL + "/images/icon-special_leave.png"} alt="" height="40" width="40" />
    )

    const bubbleState = (mainCategory, state) => (
        mainCategory !== "sick_leave" &&
            state === "validate" ? <div className="bubble_state bubble_state--green bubble_state--small">{t("State.approved")}</div>
            : (state === "confirm" || state === "validate1") ? <div className="bubble_state bubble_state--orange bubble_state--small">{t("State.pending")}</div>
                : state === "refuse" && <div className="bubble_state bubble_state--red bubble_state--small">{t("State.refused")}</div>
    )

    return (
        <div className={`absence-card ` + (size === 'small' && `${mainCategory} ${request.state}`)} id={request.id}>
            {size === 'large' ?
                <>
                    <div className="absence-card__row">
                        <div className="absence-card__header">
                            {categoryLogo(mainCategory)}
                            <div className="absence-card__header_text absence-card__header_text--large">
                                <div className="absence-card__category--large">{request.time_off_main_category ? t("Category." + request.time_off_main_category) : request.time_off_type}</div>
                                <div className="absence-card__write_date">
                                    <img src={process.env.PUBLIC_URL + "/images/icon-last-change.svg"} alt="" width="10" height="10" />
                                    {moment.utc(request.write_date).fromNow()}
                                </div>
                            </div>
                        </div>
                        <div className="bubble_duration">{!request.request_unit_half ? request.number_of_days_display : "½"}</div>
                    </div>
                    <div className="absence-card__row">
                        {request.request_unit_half ? <>
                            <div className="input">{t("RequestForm." + request.request_date_from_period)}</div>
                            <div className="to">{t("RequestDate.of")}</div>
                            <div className="input">{formatLocalDate(request.date_from)}</div>
                        </> : <>
                            <div className="input">{formatLocalDate(request.date_from)}</div>
                            <div className="to">{t("RequestDate.to")}</div>
                            <div className="input">{formatLocalDate(request.date_to)}</div>
                        </>}
                    </div>
                    <div className="absence-card__request_state">
                        {bubbleState(mainCategory, request.state)}
                    </div>
                    <div className="edit-button-box d-flex justify-content-end w-100 position-relative">
                        <button type="button" className="btn btn-edit" onClick={openDetailView}>
                            {t("Button.view")} <img className="edit-button-box__icon" src={process.env.PUBLIC_URL + "/images/icon-eye.svg"} alt="" />
                        </button>
                        <img src={process.env.PUBLIC_URL + "/images/icon-bg-labora.svg"} alt="" className="edit-button-box__bg" />
                    </div>
                </>
                : size === 'small' &&
                <div className="absence-card_small" onClick={openDetailView}>
                    <div className="absence-card__header">
                        {categoryLogo(mainCategory)}
                        <div className="absence-card__header_text absence-card__header_text--small">
                            <div className="absence-card__category--small">{request.time_off_main_category ? t("Category." + request.time_off_main_category) : request.time_off_type}</div>
                            <div className="absence-card__time_period">
                                {request.request_unit_half ? <>
                                    {t("RequestForm." + request.request_date_from_period)}
                                    <img src={process.env.PUBLIC_URL + "/images/icon-arrow-right.png"} alt="" />
                                    {moment(request.date_from).format("DD. MMM")}
                                </> : <>
                                    {moment(request.date_from).format("DD. MMM")}
                                    <img src={process.env.PUBLIC_URL + "/images/icon-arrow-right.png"} alt="" />
                                    {moment(request.date_to).format("DD. MMM")}
                                </>}
                            </div>
                        </div>
                    </div>
                    <div className="absence-card__bubbles">
                        <div className="bubble_duration">{!request.request_unit_half ? request.number_of_days_display : "½"}</div>
                        {bubbleState(mainCategory, request.state)}
                    </div>
                </div>
            }
        </div>
    )
}

export default AbsenceCard