import React, { useEffect } from 'react';
import * as microsoftTeams from "@microsoft/teams-js";
import { msalInstance } from '../config/MsalConfig';

/**
 * This component is loaded to grant consent for graph permissions.
 */
function TeamsConsentPopup() {

    useEffect(() => {
        microsoftTeams.app.initialize().then(() => {
            microsoftTeams.app.getContext().then(async (context) => {
                var loginHint = context.user.loginHint;

                const scopesRequest = {
                    scopes: ["openid", "profile"],
                    redirectUri: window.location.origin + `/auth-start`,
                    loginHint: loginHint,
                    forceRefresh: true
                };

                await msalInstance.loginRedirect(scopesRequest);
            });
        });
    }, [])

    return (
        <div>
            <h3>Redirecting to consent page.</h3>
        </div>
    );
}

export default TeamsConsentPopup;