import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

import { TRANSLATIONS_DE } from "./de/translations";
import { FAQ_DE } from "./de/faq";
import { TRANSLATIONS_EN } from "./en/translations";
import { FAQ_EN } from "./en/faq";

i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        fallbackLng: 'de',
        resources: {
            de: {
                translation: TRANSLATIONS_DE,
                faq: FAQ_DE
            },
            en: {
                translation: TRANSLATIONS_EN,
                faq: FAQ_EN
            }
        }
    });