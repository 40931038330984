import React, { createContext, useContext, useState, useEffect, useCallback, useMemo } from "react";
import { fetchAccessToken } from '../config/MsalConfig';
import axios from 'axios';
import moment from "moment";
import { useTranslation } from "react-i18next";
import "../translations/i18n";
import AbsenceCard from '../components/AbsenceCard';
import { logToDatadog } from '../index';

const absencesContext = createContext();

function isTokenMissing(token) {
  return !token;
}

function getTokenExpiryTime(token) {
  // Split the JWT, decode the payload, and parse it
  const tokenBase64 = token.split('.')[1];
  const payloadJWT = window.atob(tokenBase64);
  const decodedPayloadJWT = JSON.parse(payloadJWT);
  // Return expiration time from the token's payloadJWT
  return decodedPayloadJWT.exp ?? null;
}

function checkAccessToken(accessToken){
  // TODO remove me after fixing bug: 18525. 03.04.24
  if (isTokenMissing(accessToken)){
    logToDatadog("getDataFromAPI Missing Token", "error");
    throw new Error("missing_accessToken");
  } else {
    const expTime = getTokenExpiryTime(accessToken);
    const currentTime = Math.floor(Date.now() / 1000);
    if (expTime && currentTime){
      if (currentTime >= expTime) {
        console.log('Expired Token!');
        logToDatadog("getDataFromAPI Expired Token", "error");
      } else if ((expTime - currentTime) < 300){
        console.log('Expired within 5 minutes');
        logToDatadog("getDataFromAPI Expired within 5 minutes");
      }
    }
  };
}

export function AbsencesContext({ children }) {
  const { t, i18n } = useTranslation();
  moment.locale(i18n.language);

  const [absencesData, setAbsencesData] = useState([]);
  const [publicHolidaysCollection, setPublicHolidaysCollection] = useState([]);
  const [absenceCategories, setAbsenceCategories] = useState([]);
  const [statsCurrentYear, setStatsCurrentYear] = useState({});

  const [absenceDetails, setAbsenceDetails] = useState({});
  const [stateRequestForm, setStateRequestForm] = useState({ display: false });
  const [activeDate, setActiveDateInContext] = useState(moment());
  const [PreviousDate, setPreviousDateInContext] = useState(false);

  const [isLoadingAPI, setIsLoadingAPI] = useState(true);
  const [errorAPI, setErrorAPI] = useState(undefined);
  const [isLoadingRequest, setIsLoadingRequest] = useState(false);
  const [formErrorMsg, setFormErrorMsg] = useState(undefined);
  const [successMessageRequest, setSuccessMessageRequest] = useState(undefined);

  // Gets all data for actively selected year
  const absencesSelectedYear = useMemo(() => { 
    const absences = absencesData.filter(el => moment(el.date_from).isSame(activeDate, 'year'))
    const filteredAbsences = (publicHolidaysCollection || []).map(publicHoliday => ({
      time_off_main_category: "public_holiday",
      date_from: publicHoliday.datetime_from,
      date_to: publicHoliday.datetime_to,
      state: "validate",
      ...publicHoliday,
    })).concat(absences);
    return filteredAbsences
  }, [absencesData, activeDate]);

    const absencesSelectedMonth = useMemo(() => {
    return absencesData.filter(el => moment(el.date_from).isSame(activeDate, 'month') || moment(el.date_to).isSame(activeDate, 'month'));
  }, [absencesData, activeDate])

  function displayErrorMessage(error, error_type) {
    const message = error.response?.data?.description || error.message;
    const formattedMessage = message.replace(/<[^>]+>|[()']/g, '').replace(/\\n/g, '\r\n').replace('&quot;', '').replace('Absences API: ', '').replace(', None', '');
    if (error_type === "api"){
      logToDatadog(`An error occurred when fetching data from Absence-API: ${error}`, "error");
      setErrorAPI(formattedMessage);
    }
    if (error_type === "form"){
      logToDatadog(`An error occurred when using Form with Absence-API: ${error}`, "error");
      setFormErrorMsg(formattedMessage);
    }
    setIsLoadingRequest(false);
  }

  function displaySuccessMessage(success_message) {
    setStateRequestForm({ display: false })
    setFormErrorMsg("")
    setSuccessMessageRequest(success_message);
    setTimeout(() => {
      setSuccessMessageRequest("");
    }, 8000)
  }

  const getDataFromAPI = useCallback(async (year) => {
    setIsLoadingAPI(true);
    try {
      await fetchAccessToken();
      const accessToken = axios.defaults.headers.common['authorization'] ?? null;
      checkAccessToken(accessToken); 
      const [absencesResponse, categoriesResponse, statsResponse, publicHolidaysCurrentYearResponse] = await Promise.all([
        axios.get(`${process.env.REACT_APP_ODOO_API_URL}/absence/absence`),
        axios.get(`${process.env.REACT_APP_ODOO_API_URL}/absence/absence-type-category`),
        axios.get(`${process.env.REACT_APP_ODOO_API_URL}/absence/stats?year=${year}`),
        axios.get(`${process.env.REACT_APP_ODOO_API_URL}/absence/public-holidays?year=${year}`)
      ]);
      setAbsencesData(absencesResponse.data.data.filter(el => el.active === true && el.state !== "draft"));
      setAbsenceCategories(categoriesResponse.data);
      setAbsenceStats(statsResponse.data.data[year]);
      setPublicHolidaysCollection(publicHolidaysCurrentYearResponse.data.data[year]);
    } catch (getDataFromApiError) {
      displayErrorMessage(getDataFromApiError, "api");
    }
    setIsLoadingAPI(false);
  }, []);
  
  useEffect(() => {
    if (PreviousDate && PreviousDate.year() === activeDate.year()){
      return;
    } else {
      getDataFromAPI(activeDate.year());
    }
  }, [getDataFromAPI, activeDate]);

  const getAbsenceDetails = async (id) => {
    if (!id) {
      setAbsenceDetails(undefined);
      return undefined;
    }

    try {
      await fetchAccessToken();
      const accessToken = axios.defaults.headers.common['authorization'] ?? null;
      checkAccessToken(accessToken); 
      const response = await axios.get(`${process.env.REACT_APP_ODOO_API_URL}/absence/absence/${id}`);
      setAbsenceDetails(response.data);
      return response.data;
    } catch (AbsenceDetailsError) {
      displayErrorMessage(AbsenceDetailsError, "form");
      throw AbsenceDetailsError;
    }
  };

  /**
   * Sets employee absences statistics for the current year.
   * @param {Array} statsCurrentYear - The array of statistics for the current year.
   * @returns {undefined} This function does not have a return value.
   */
  const setAbsenceStats = (statsCurrentYear) => {
    if(statsCurrentYear && statsCurrentYear.length === 0){
      const updatedStats = {
        max_leaves: 0,
        used: 0,
        available: 0,
        pending: 0,
        remaining_previous_year: 0,
        sick_days: 0
      };
      setStatsCurrentYear(updatedStats);
      return;
    }

    const holidayStats = statsCurrentYear.find((stat) => stat.payroll_code === "U" || stat.payroll_code === "SU");

    if (holidayStats) {
      const { data } = holidayStats;

      const remainingLastYear = statsCurrentYear.find((stat) => stat.payroll_code === "RU")?.data?.virtual_remaining_leaves;
      
      const sickLeaveStats = statsCurrentYear.filter((stat) => stat.main_category === "sick_leave");
      const sickDays = sickLeaveStats.length > 0 ? sickLeaveStats.reduce((sum, stat) => sum + stat.data.leaves_taken, 0) : undefined;
      
      const pendingData = (data.remaining_leaves - data.virtual_remaining_leaves);
      const roundedPendingData = Math.round(pendingData * 100) / 100;
      
      const updatedStats = {
        max_leaves: data.max_leaves,
        used: data.leaves_taken,
        available: data.virtual_remaining_leaves,
        pending: roundedPendingData,
        remaining_previous_year: remainingLastYear,
        sick_days: sickDays
      };
      setStatsCurrentYear(updatedStats);
    }
  };

  const sleep = (milliseconds) => {
    return new Promise(resolve => setTimeout(resolve, milliseconds));
  }

  const uploadSickNote = async (e, id, file) => {
    e.preventDefault();
    try {
      await fetchAccessToken();
      // file upload for sick notes should delayed to comply with (POST) API limits. (1 post request, per 2 seconds)
      const accessToken = axios.defaults.headers.common['authorization'] ?? null;
      checkAccessToken(accessToken); 
      await sleep(2000);
      await axios.post(`${process.env.REACT_APP_ODOO_API_URL}/absence/absence/${id}/upload-attachment`, file, {
        headers: { 'content-type': file.type }
      });
      displaySuccessMessage(t("Alert.success_message_sick_leave"));
      setSuccessMessageRequest(t('Alert.sick_note_uploaded'));
      setTimeout(() => setSuccessMessageRequest(''), 20000);
      getDataFromAPI(activeDate.year());
      setIsLoadingRequest(false);
    } catch (uploadSickNoteError) {
      displayErrorMessage(uploadSickNoteError, 'form');
    }
  };

  const postAbsenceRequest = async (e, time_off_main_category, time_off_category, date_from, date_to, request_unit_half, request_date_from_period, electronic_certificate, incapacity_start_date, file_sick_note, name) => {
    e.preventDefault();
    try {
      await fetchAccessToken();
      const accessToken = axios.defaults.headers.common['authorization'] ?? null;
      checkAccessToken(accessToken); 
      const res = await axios.post(`${process.env.REACT_APP_ODOO_API_URL}/absence/absence`, {
        "time_off_category": time_off_category,
        "date_from": moment(date_from).format('YYYY-MM-DD'),
        "date_to": moment(date_to).format('YYYY-MM-DD'),
        "request_unit_half": request_unit_half,
        "request_date_from_period": request_date_from_period,
        "electronic_certificate": electronic_certificate,
        "incapacity_start_date": incapacity_start_date ? moment(incapacity_start_date).format('YYYY-MM-DD') : undefined,
        "name": name
      });
      if (time_off_main_category === "sick_leave" && file_sick_note && res.data.id) {
        uploadSickNote(e, res.data.id, file_sick_note);
      } else {
        displaySuccessMessage((time_off_main_category === "sick_leave") ? t("Alert.success_message_sick_leave") : t("Alert.success_message_time_off_paid"));
        getDataFromAPI(activeDate.year());
        setIsLoadingRequest(false);
    }
    } catch (postAbsenceError) {
      displayErrorMessage(postAbsenceError, "form");
    }
  }


  const updateHolidayRequest = async (e, id, date_from, date_to, request_unit_half, request_date_from_period, name) => {
    e.preventDefault();
    try {
      await fetchAccessToken();
      const accessToken = axios.defaults.headers.common['authorization'] ?? null;
      checkAccessToken(accessToken); 
      await axios.put(`${process.env.REACT_APP_ODOO_API_URL}/absence/absence/${id}`, {
        "date_from": moment(date_from).format('YYYY-MM-DD'),
        "date_to": moment(date_to).format('YYYY-MM-DD'),
        "request_unit_half": request_unit_half,
        "request_date_from_period": request_date_from_period,
        "name": name
      });
      displaySuccessMessage(t("Alert.success_message_update"));
      getDataFromAPI(activeDate.year());
      setIsLoadingRequest(false);
    } catch (updateHolidayError) {
      displayErrorMessage(updateHolidayError, "form");
    }
  };

  const updateSickLeave = async (e, id, date_to, electronic_certificate, incapacity_start_date, file_sick_note, name) => {
    e.preventDefault();
    try {
      await fetchAccessToken();
      const accessToken = axios.defaults.headers.common['authorization'] ?? null;
      checkAccessToken(accessToken); 
      await axios.put(`${process.env.REACT_APP_ODOO_API_URL}/absence/absence/${id}`, {
        "date_to": moment(date_to).format('YYYY-MM-DD'),
        "electronic_certificate": electronic_certificate,
        "incapacity_start_date": incapacity_start_date ? moment(incapacity_start_date).format('YYYY-MM-DD') : undefined,
        "name": name
      });
      if (file_sick_note) {
        uploadSickNote(e, id, file_sick_note);
      }
      displaySuccessMessage(t("Alert.success_message_update"));
      getDataFromAPI(activeDate.year());
      setIsLoadingRequest(false);
    } catch (updateSickLeaveError) {
      displayErrorMessage(updateSickLeaveError, "form");
    }
  }

  const refuseHolidayRequest = async (e, id, comment) => {
    e.preventDefault();
    try {
      await fetchAccessToken();
      const accessToken = axios.defaults.headers.common['authorization'] ?? null;
      checkAccessToken(accessToken); 
      await axios.post(`${process.env.REACT_APP_ODOO_API_URL}/absence/absence/${id}/refuse`, { "comment": comment });
      displaySuccessMessage(t("Alert.success_message_refusal"));
      getDataFromAPI(activeDate.year());
    } catch (refuseHolidayError) {
      displayErrorMessage(refuseHolidayError, "form");
    }
  };

  const deleteHolidayRequest = async (e, id) => {
    e.preventDefault();
    try {
      await fetchAccessToken();
      const accessToken = axios.defaults.headers.common['authorization'] ?? null;
      checkAccessToken(accessToken); 
      await axios.put(`${process.env.REACT_APP_ODOO_API_URL}/absence/absence/${id}`, { "active": false });
      displaySuccessMessage(t("Alert.success_message_deletion"));
      getDataFromAPI(activeDate.year());
    } catch (deleteHolidayError) {
      displayErrorMessage(deleteHolidayError, "form");
    }
  }

  const LeaveRequestsOfSelectedMonth = () => {
    return absencesSelectedMonth.slice().reverse().map((request, key) => {
      return <AbsenceCard key={key} request={request} size={'small'} />
    })
  }

  function clearMarkedDaysInDatePicker() {
    const markedDays = document.querySelectorAll('.day');
    markedDays.forEach(day => {
      if (day){
        if (day.hasAttribute('public_holiday')){          
          day.removeAttribute('public_holiday');
        }
        if (day.hasAttribute('time_off')){
          day.removeAttribute('time_off');
        }
        if (day.hasAttribute('sick_leave')){
          day.removeAttribute('sick_leave');
        }
        if (day.hasAttribute('special_leave')){
          day.removeAttribute('special_leave');
        }
        if (day.classList){
          if (day.classList.contains('disabled')){
            return
          }
          day.classList.remove('selected', 'end', 'hovered', 'disabled');
        }
      }
    });
  };

  const isMobileDevice = () => {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
}
  
  const markDaysInDatePickerDesktop = useCallback(() => {
    absencesSelectedYear?.forEach(absence => {
      const absenceTypeAttrName = getAbsenceAttribute(absence.time_off_main_category);
      const startDate = moment(absence.date_from).startOf("day");
      const endDate = moment(absence.date_to).startOf("day");
      let currentDate = moment(startDate);
      const monthCalendar = document.querySelectorAll(`.month-calendar[data-month-index='${currentDate.format("M")}']`)[0];
      if (!monthCalendar) return;
      if (absence.state !== "validate") return;
      const startDaySelector = document.querySelectorAll(`.day[data-date-value='${moment(startDate).valueOf()}']`)[0];
      if (startDaySelector && absenceTypeAttrName){
        startDaySelector?.classList.add(absenceTypeAttrName, "selected");
        startDaySelector.setAttribute(absenceTypeAttrName, true);
      };
      const endDaySelector = document.querySelectorAll(`.day[data-date-value='${moment(endDate).valueOf()}']`)[0];

      if (endDaySelector && absenceTypeAttrName){        
        endDaySelector?.classList.add(absenceTypeAttrName, "selected", "end");
        endDaySelector.setAttribute(absenceTypeAttrName, true);
      };
      
      while (currentDate.isSameOrBefore(endDate)) {
        const currentDateSelector = document.querySelectorAll(`.day[data-date-value='${currentDate.valueOf()}']`)[0];
        if (currentDateSelector && absenceTypeAttrName){
          currentDateSelector?.classList.add(absenceTypeAttrName, (moment(startDate).isBefore(moment(endDate)) && "hovered"));
          currentDateSelector.setAttribute(absenceTypeAttrName, true);
        };
        currentDate.add(1, 'days');
      }
    });

  }, [absencesSelectedYear])

  const updateMarkedDaysInDatePickerDesktop = useCallback(() => {
    if (isMobileDevice()) {
      logToDatadog("Marking Days in DatePicker ignored for Mobile Device.");
      return;
    }
    const setMarkers = () => {
      clearMarkedDaysInDatePicker();
      setTimeout(() => markDaysInDatePickerDesktop(), 250);
    };
    setMarkers();
    const dialogDatePickerButton = document.querySelectorAll(".flipper-button");
    dialogDatePickerButton.forEach(button => button.addEventListener("click", setMarkers));


     // Setting up a MutationObserver to observe when Dialog Date Picker has been opened by user.
    const observer = new MutationObserver((dialogDatePickerOpened) => {
      dialogDatePickerOpened.forEach((mutation) => {
        if (mutation.type === 'attributes' && mutation.attributeName === 'class') {
          const targetElement = mutation.target;
          if (targetElement.classList.contains('open')) {
            // Mark datepickers dates.
            setMarkers();
          }
        }
      });
    });

    const dialogDatePicker = document.querySelector('.dialog-date-picker');
    if (dialogDatePicker){
      const config = { attributes: true, childList: false, subtree: false };
      observer.observe(dialogDatePicker, config);
    }

    return () => {
      dialogDatePickerButton.forEach(button => button.removeEventListener("click", setMarkers));
      observer.disconnect();
    };
  }, [markDaysInDatePickerDesktop])

  function getDaysBetweenDateAndToday(dateFrom) {
    return moment(dateFrom).diff(moment(), "days");
  }

  function getAbsenceAttribute(time_off_main_category) {
    const absenceMainCategoryMapping = {
      "public_holiday": "public_holiday",
      "sick_leave": "sick_leave",
      "time_off": "time_off",
      "special_leave": "special_leave"
    };
    return absenceMainCategoryMapping[time_off_main_category] || "uncategorized";
  }

  const setActiveDate = async (year) => {
    setPreviousDateInContext(activeDate);
    setActiveDateInContext(year);
  }

  return (
    <absencesContext.Provider
      value={{
        absencesData,
        absenceDetails,
        absencesSelectedMonth,
        absencesSelectedYear,
        absenceCategories,
        publicHolidaysCollection,
        statsCurrentYear,
        stateRequestForm,
        setStateRequestForm,
        activeDate,
        setActiveDate,
        formErrorMsg,
        setFormErrorMsg,
        errorAPI,
        successMessageRequest,
        isLoadingAPI,
        isLoadingRequest,
        setIsLoadingRequest,
        getAbsenceDetails,
        postAbsenceRequest,
        updateHolidayRequest,
        updateSickLeave,
        refuseHolidayRequest,
        deleteHolidayRequest,
        LeaveRequestsOfSelectedMonth,
        updateMarkedDaysInDatePickerDesktop,
        getDaysBetweenDateAndToday
      }}
    >
      {children}
    </absencesContext.Provider>
  )
}

export function useAbsenceAPI() {
  return useContext(absencesContext)
}