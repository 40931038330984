import React from 'react';
import { useAbsenceAPI } from '../contexts/AbsencesContext';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';

const MainMenu = ({ toggleOverlay }) => {
    const { statsCurrentYear } = useAbsenceAPI();
    const navigate = useNavigate();
    const { t, i18n } = useTranslation();

    const changeLanguage = (language) => {
        i18n.changeLanguage(language);
        window.location.reload();
    };

    return (
        <div className="main-menu-component bg-feed view box align-items-center">
            <div className="page-container__divider d-xl-none" />
            {Object.keys(statsCurrentYear).length > 0 && <div className="d-xl-none d-flex flex-row justify-content-center">
                <div className="row-holiday-stats__single">{t("Stats.used")}<div className="row-holiday-stats__single-bubble_state">{statsCurrentYear.used}</div></div>
                <div className="row-holiday-stats__single">{t("Stats.available")}<div className="row-holiday-stats__single-bubble_state">{statsCurrentYear.available}</div></div>
                <div className="row-holiday-stats__single">{t("Stats.pending")}<div className="row-holiday-stats__single-bubble_state">{statsCurrentYear.pending}</div></div>
            </div>}
            <ul className="main-menu default-box">
                <Link to="/" className="main-menu__list-item" onClick={e => { e.preventDefault(); toggleOverlay(false); navigate("/"); window.location.reload(); }}>
                    <img src={process.env.PUBLIC_URL + "/images/icon-home.svg"} alt="" width={18} height={18} />
                    <div className="main-menu__list-item-text">{t("MainMenu.home")}</div>
                </Link>
                <div className="main-menu__divider" />
                <Link to="/faq" className="main-menu__list-item" onClick={e => { e.preventDefault(); toggleOverlay(false); navigate("/faq") }}>
                    <img src={process.env.PUBLIC_URL + "/images/icon-information.svg"} alt="" width={18} height={18} />
                    <div className="main-menu__list-item-text">{t("MainMenu.faq")}</div>
                </Link>
                <div className="main-menu__divider" />
                <li className="main-menu__list-item">
                    <img src={process.env.PUBLIC_URL + "/images/icon-globe.svg"} alt="" width={18} height={18} />
                    <select className="form-select border-0 main-menu__list-item-text" onChange={(e) => changeLanguage(e.target.value)}>
                        <option defaultValue hidden value="" className="main-menu__list-item-text">{t("MainMenu.language")}</option>
                        <option value="de" className="main-menu__list-item-text">Deutsch</option>
                        <option value="en" className="main-menu__list-item-text">English</option>
                    </select>
                </li>
                <div className="main-menu__divider" />
                <li className="main-menu__list-item" onClick={() => window.location.href = "mailto:support@solvti.pl"}>
                    <img src={process.env.PUBLIC_URL + "/images/icon-mail.svg"} alt="" width={18} height={18} />
                    <div className="main-menu__list-item-text">{t("MainMenu.email_support")}</div>
                </li>
                <div className="main-menu__divider" />
                <Link to="/logout" className="main-menu__list-item">
                    <img src={process.env.PUBLIC_URL + "/images/icon-logout.svg"} alt="" width={18} height={18} />
                    <div className="main-menu__list-item-text main-menu__list-item-text--red">{t("MainMenu.logout")}</div>
                </Link>
            </ul>
        </div>
    )
}

export default MainMenu