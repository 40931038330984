import React from 'react'
import { useNavigate } from "react-router-dom";
import { Accordion } from 'react-bootstrap'
import './FAQ.scss'

const FAQ = ({ t }) => {
  const navigate = useNavigate();

  return (
    <div className="faq page-container bg-feed box w-100">
      <div className="page-container__divider d-xl-none" />
      <h4 className="mt-2">{t("MainMenu.faq")}</h4>
      <div className="faq__video-container"><iframe id="video" src={t("FAQ.video_url")} allowFullScreen title="Absences Tutorial" /></div>
      <h5 className="my-2">{t("FAQ.heading")}</h5>
      <Accordion className="w-100">
        <Accordion.Item eventKey="1">
          <Accordion.Header><h6 className="m-0">{t("Q1.question", { ns: "faq" })}</h6></Accordion.Header>
          <Accordion.Body>
            {t("Q1.answer_1", { ns: "faq" })}<br /><br />
            <img src="images/faq-overview-app.png" alt="" className="py-2" /><br />
            {t("Q1.answer_2", { ns: "faq" })}<br /><br />
            {t("Q1.answer_3", { ns: "faq" })}
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="2">
          <Accordion.Header><h6 className="m-0">{t("Q2.question", { ns: "faq" })}</h6></Accordion.Header>
          <Accordion.Body>
            {t("Q2.answer_1", { ns: "faq" })}<br /><br />
            <img src="images/faq-request-absence.png" alt="" className="py-2" /><br />
            {t("Q2.answer_2", { ns: "faq" })}<br /><br />
            <img src="images/faq-datepicker.png" alt="" className="py-2" /><br />
            {t("Q2.answer_3", { ns: "faq" })}<br /><br />
            {t("Q2.answer_4", { ns: "faq" })}<br /><br />
            {t("Q2.answer_5", { ns: "faq" })}
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="3">
          <Accordion.Header><h6 className="m-0">{t("Q3.question", { ns: "faq" })}</h6></Accordion.Header>
          <Accordion.Body>
            {t("Q3.answer_1", { ns: "faq" })}<br /><br />
            <img src="images/faq-details-button.png" alt="" className="py-2" width="400" /><br />
            <img src="images/faq-edit-button.png" alt="" className="py-2" width="400" /><br />
            {t("Q3.answer_2", { ns: "faq" })}
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="4">
          <Accordion.Header><h6 className="m-0">{t("Q4.question", { ns: "faq" })}</h6></Accordion.Header>
          <Accordion.Body>
            {t("Q4.answer_1", { ns: "faq" })}<br /><br />
            {t("Q4.answer_2", { ns: "faq" })}<br /><br />
            <img src="images/faq-select-category.png" alt="" className="py-2" width="400" /><br />
            {t("Q4.answer_3", { ns: "faq" })}<br /><br />
            <img src="images/faq-upload-sick-note.png" alt="" className="py-2" width="400" />
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="5">
          <Accordion.Header><h6 className="m-0">{t("Q5.question", { ns: "faq" })}</h6></Accordion.Header>
          <Accordion.Body>
            {t("Q5.answer_1", { ns: "faq" })}<br /><br />
            {t("Q5.answer_2", { ns: "faq" })}<br /><br />
            <img src="images/faq-edit-sick-leave.png" alt="" className="py-2" width="400" /><br />
            {t("Q5.answer_3", { ns: "faq" })}
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="6">
          <Accordion.Header><h6 className="m-0">{t("Q6.question", { ns: "faq" })}</h6></Accordion.Header>
          <Accordion.Body>
            {t("Q6.answer", { ns: "faq" })}<br /><br />
            <img src="images/faq-feedback-button.png" alt="" className="py-2" />
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
      <div className="footer-button-mobile d-xl-none">
        <button className="btn-secondary" onClick={() => { navigate("/"); }}>
          <img src={process.env.PUBLIC_URL + "/images/icon-btn-back.svg"} alt="" />
          {t("Button.back")}
        </button>
      </div>
    </div >
  )
}

export default FAQ