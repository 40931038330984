export const TRANSLATIONS_EN = {
    Alert: {
        critical_error: "The following error has occurred, please contact support:",
        refresh_page: "Unable to fetch data from the service. Please refresh the page",
        problem_repeats: "If this problem repeats, please logout and try again.",
        error_select_required_fields: "Please select the type, as well as the start and end date of the absence.",
        success_message_time_off_paid: "Your Holiday Request has been successfully sent!",
        success_message_sick_leave: "Please also inform the responsible team leader and project planner and, if necessary, the direct team members about your absence. Get well soon!",
        success_message_update: "Your absence request has been updated.",
        success_message_refusal: "Your Holiday Request has been withdrawn.",
        success_message_deletion: "Your pending Holiday Request has been deleted.",
        sick_note_uploaded: "Your sick note has been successfully uploaded."
    },
    Home: {
        welcome: "Welcome 👋",
        last_requests: "Last Requests",
        absences: "Absences",
        year_overview: "Year Overview",
    },
    FAQ: {
        heading: "A brief introduction to the key features of the app",
        video_url: "https://gmsholding.sharepoint.com/sites/Personal/_layouts/15/embed.aspx?UniqueId=aed495ce-d945-41ee-9f9d-3c947e427e7b&embed=%7B%22af%22%3Atrue%2C%22hvm%22%3Atrue%2C%22ust%22%3Atrue%7D&referrer=StreamWebApp&referrerScenario=EmbedDialog.Create"
    },
    MainMenu: {
        home: "Home",
        faq: "Help / FAQ",
        language: "Language",
        email_support: "Email Support",
        logout: "Log Out"
    },
    Category: {
        categories: "Categories",
        time_off: "Holiday",
        sick_leave: "Sick Leave",
        special_leave: "Special Leave",
        public_holiday: "Public Holiday"
    },
    State: {
        states: "States",
        approved: "Approved",
        pending: "Pending",
        refused: "Refused"
    },
    Stats: {
        used: "Used",
        available: "Available",
        pending: "Pending",
        remaining_previous_year: "Remaining holiday",
        sick_days: "Sick Days"
    },
    TooltipStats: {
        available: "Vacation days that can still be freely requested - open requests are already subtracted",
        used: "Vacation days already taken or approved",
        pending: "Requests currently waiting for supervisor approval",
        remaining_previous_year: "Remaining vacation entitlement from previous year.",
        sick_days: "Sick days in the current year"
    },
    RequestList: {
        next_absences: "Next Absences",
        all_absences: "All absences",
        view_all: "View All",
        collapse: "Collapse",
        no_absences_available: "No absences available",
        no_absences_planned_in_near_future: "No absences planned in the near future",
        stats_summary: "Summary"
    },
    RequestForm: {
        half_day: "Half Day",
        am: "Morning",
        pm: "Afternoon",
        upload_sick_note: "Upload Sick Note",
        electronic_certificate: "Electronic sickness certificate",
        first_day_of_incapacity: "First day of medical sick leave",
        add_comment: "Something to Add?",
        placeholder_category_select: "Absence Type"
    },
    Button: {
        back: "Back",
        request: "Request",
        cancel: "Cancel Request",
        delete: "Delete Request",
        submit: "Submit Request",
        update: "Update Request",
        view: "View",
        edit: "Edit"
    },
    RequestDate: {
        single: "Date",
        start: "Start Date",
        end: "End Date",
        of: "of",
        to: "to",
        to_the: "to the"
    },
    ModalRefuse: {
        comment_placeholder: "Why do you want to withdraw the request for absence?",
        confirm_refusal: "Do you really want to withdraw the request for absence? This cannot be undone.",
        cancel: "Cancel",
        refuse: "Yes, withdraw request"
    },
    Message: {
        request_to_approve: "Your request was submitted for approval.",
        request_approved: "approved your Holiday Request.",
        request_refused: "refused your Holiday Request.",
        request_withdrawn: "You have withdrawn your Holiday Request.",
        sick_leave_submitted: "Your sick leave was submitted."
    },
    CategoryNote: {
        sick_leave_work_accident: "Important. The accident at work must also be reported using this form:",
        sick_leave_work_accident_url: "https://unfallmeldung.crmz.org"
    },
    TooltipCalendar: {
        public_holiday_on: "Public holiday on"
    }
};