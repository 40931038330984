export const FAQ_DE = {
    Q1: {
        question: 'Statistiken, Nächste Abwesenheiten und Kalender',
        answer_1: 'Du findest im oberen Bereich des Bildschirms die Statistiken für das laufende Jahr. Falls du Resturlaub hast, wird dieser ebenfalls in der Statistik angezeigt. Als Nutzer der mobilen Anwendung, siehst du die Statistik ebenfalls, wenn du das Menü durch einen Klick auf dein Profilbild öffnest.',
        answer_2: 'Darüber hinaus werden die drei aktuellsten Abwesenheiten angezeigt, sodass du einen guten Überblick über deine aktuellen Krankheits- und Urlaubstage hast. Wenn du auf „Alle anzeigen“ klickst, wird die Liste für das gesamte Jahr erweitert.',
        answer_3: 'Der kleine Kalender ermöglicht dir einen schnellen Überblick über einzelne Monate. Hier kannst du Abwesenheiten direkt anklicken, um alle Details zu sehen oder gegebenenfalls Änderungen vorzunehmen. Unter dem kleinen Kalender hast du die Möglichkeit, zur Jahresübersicht zu gelangen und den vollen Überblick über das gesamte Jahr zu erhalten.'
    },
    Q2: {
        question: 'Urlaub beantragen',
        answer_1: 'Um einen neuen Urlaubsantrag zu stellen, klicke auf den grünen Button „Antrag stellen“. Ein Formular öffnet sich, in dem du Details zu deinem Urlaubsantrag angeben kannst.',
        answer_2: 'Als Erstes wählst du „Urlaub“ bei der Art der Abwesenheit aus. Anschließend kannst du das Start- und Enddatum angeben. Durch Klicken in das Textfeld öffnet sich ein Kalender, in dem du den Zeitraum auswählen kannst. Nach der Auswahl bestätige den Zeitraum durch den Button unten rechts.',
        answer_3: 'Möchtest du einen halben Tag Urlaub nehmen, aktiviere den Regler bei „Halber Tag“. Nun kannst du den entsprechenden Tag wählen und angeben, ob die Abwesenheit vormittags oder nachmittags ist.',
        answer_4: 'Wenn du einen Hinweis zu deinem Urlaubsantrag hast, kannst du diesen im Kommentarfeld eingeben.',
        answer_5: 'Klicke abschließend auf „Antrag einreichen“. Dein Antrag geht direkt im System an deinen Vorgesetzten und kann von ihm freigegeben werden. Über die Freigabe wirst du per E-Mail informiert und kannst ihn dann deinem Outlook-Kalender hinzufügen.'
    },
    Q3: {
        question: 'Urlaub ändern',
        answer_1: 'Bestehende Urlaubsanträge können im System so lange frei geändert werden, solange sie noch nicht freigegeben wurden. Hier kannst du alle Informationen im System aktualisieren. Öffne dazu den entsprechenden Urlaub, indem du in der Liste auf „Details“ klickst.',
        answer_2: 'Wenn dein Urlaubsantrag bereits freigegeben wurde, kannst du ihn bis 7 Tage vor Urlaubsbeginn aus dem System entfernen, indem du in der Detailansicht auf „Antrag stornieren“ klickst. Bitte beachte, dass der Urlaub dann direkt aus dem System entfernt wird. Möchtest du Änderungen an bereits freigegebenem Urlaub vornehmen, wende dich bitte an deinen Vorgesetzten, damit er diese Änderungen entsprechend berücksichtigen kann.',
    },
    Q4: {
        question: 'Krankheit melden',
        answer_1: 'Wenn du aufgrund von Krankheit nicht zur Arbeit erscheinen kannst, ist es wichtig, dass wir diese Information so schnell wie möglich erhalten. Über die App kannst du eine Arbeitsunfähigkeit melden.',
        answer_2: 'Klicke dazu auf den Button „Antrag stellen“, um das Formular zu öffnen. Wähle den entsprechenden Typ aus und sende das Formular anschließend ab.',
        answer_3: 'Falls du eine Arbeitsunfähigkeitsbescheinigung von deinem Arzt erhalten hast, kannst du diese entweder in Papierform oder als elektronische AU anhängen.'
    },
    Q5: {
        question: "Krankheit ändern",
        answer_1: "Es kann vorkommen, dass du länger erkrankt bist als bei deiner ersten Meldung angegeben. In diesem Fall kannst du eine bereits aktive Krankheitsmeldung aktualisieren und ein neues voraussichtliches Enddatum angeben. Außerdem kannst du über das Kommentarfeld zusätzliche Informationen an deinen Vorgesetzten senden.",
        answer_2: 'Beim Update hast du die Möglichkeit, folgende Informationen zu aktualisieren: Enddatum, Kommentar und Arbeitsunfähigkeitsbescheinigung.',
        answer_3: 'Für alle anderen Fälle wende dich bitte an deinen HR-Manager.'
    },
    Q6: {
        question: "Hilfe, etwas funktioniert nicht.",
        answer: "Falls es Probleme gibt oder etwas nicht wie erhofft funktioniert, kann dir in der Regel dein HR-Manager helfen. Wenn du der Meinung bist, dass es sich um technische Probleme handelt, nutze bitte den Button „Feedback“ auf der rechten Seite. Dadurch kannst du einen Screenshot an das Ticketsystem senden und wir werden uns schnellstmöglich um eine Lösung kümmern.",
    }
}