import React, { useEffect } from 'react';
import { useAbsenceAPI } from '../../contexts/AbsencesContext';
import { useCalendar } from '../../contexts/CalendarContext';
import { useNavigate } from "react-router-dom";
import moment from 'moment';
import { Dropdown, Form } from 'react-bootstrap';
import '../../styles/calendar.scss';
import './YearCalendar.scss';

const YearCalendar = ({ t }) => {
    const { absencesSelectedYear, activeDate } = useAbsenceAPI();
    const { filters, setFilters, getCalendarMonthContent, getAllWeekdaysShort, markAbsencesInCalendar, YearSelect } = useCalendar();
    const navigate = useNavigate();

    const weekdaysShort = getAllWeekdaysShort();

    useEffect(() => {
        absencesSelectedYear?.length > 0 && markAbsencesInCalendar(absencesSelectedYear, false);
    }, [markAbsencesInCalendar, absencesSelectedYear])

    const handleCheck = e => {
        let newArray = [...filters, e.target.value]
        if (filters.includes(e.target.value)) {
            newArray = newArray.filter(value => value !== e.target.value)
        }
        setFilters(newArray)
    }

    const checkboxesFilter = {
        categories: [{ value: "time_off", label: t("Category.time_off") }, { value: "sick_leave", label: t("Category.sick_leave") }, { value: "special_leave", label: t("Category.special_leave") }],
        states: [{ value: "validate", label: t("State.approved") }, { value: "confirm", label: t("State.pending") }, { value: "refuse", label: t("State.refused") }]
    }

    const FilterOptions = ({ checkboxArray, name }) => {
        let options = []
        for (const [id, field] of Object.entries(checkboxArray)) {
            options.push(<Form.Check type="checkbox" className="mx-3" name={`${name}[]`} key={name + '-' + id} id={name + '-' + id} label={field.label} value={field.value} checked={filters.includes(field.value)} onChange={e => handleCheck(e)} />)
        }
        return options
    }

    const getContentOfYear = () => {
        const months = Array.from({ length: 12 }, (_, i) => {
            const month = moment(activeDate).month(i);
            return (
                <div className="year-calendar-month-wrapper" key={`month-${i}`}>
                    <div className="year-calendar-month-wrapper__heading">
                        {moment(month).format('MMMM')}
                    </div>
                    <div className="d-flex flex-column" style={{ gap: "17px" }}>
                        <div className="calendar__weekdays-row">
                            {weekdaysShort.map((weekdayShort, index) => (
                                <div className="calendar__weekday-short" key={index}>{weekdayShort}</div>
                            ))}
                        </div>
                        {getCalendarMonthContent(month, false)}
                    </div>
                </div>
            );
        });
        return months;
    }

    return (
        <div className="year-calendar page-container view">
            <div className="page-container__divider d-xl-none" />
            <div className="topbar d-flex justify-content-between">
                <button className="year-calendar__btn-back btn-secondary d-none d-xl-flex" onClick={() => navigate("/")}>
                    <img src={process.env.PUBLIC_URL + "/images/icon-btn-back.svg"} alt="" />
                    {t("Button.back")}
                </button>
                <YearSelect />
                <Dropdown autoClose="outside" className="year-calendar__filter">
                    <Dropdown.Toggle className="form-select">
                        <span className="float-start"> Filter </span>
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                        <Dropdown.Header>{t("Category.categories")}</Dropdown.Header>
                        <FilterOptions checkboxArray={checkboxesFilter.categories} name={"categories"} />
                        <Dropdown.Divider />
                        <Dropdown.Header>{t("State.states")}</Dropdown.Header>
                        <FilterOptions checkboxArray={checkboxesFilter.states} name={"states"} />
                    </Dropdown.Menu>
                </Dropdown>
            </div>
            <div className="year-calendar__content">
                {getContentOfYear()}
            </div>
            <div className="footer-button-mobile d-xl-none">
                <button className="btn-secondary" onClick={() => navigate("/")}>
                    <img src={process.env.PUBLIC_URL + "/images/icon-btn-back.svg"} alt="" />
                    {t("Button.back")}
                </button>
            </div>
        </div>
    )
}

export default YearCalendar