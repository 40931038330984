import React, { createContext, useContext, useState, useCallback } from "react";
import AbsenceCard from "../components/AbsenceCard";
import { useAbsenceAPI } from '../contexts/AbsencesContext';
import { useTranslation } from 'react-i18next';
import moment from "moment";

const calendarContext = createContext();

export function CalendarContext({ children }) {
    const { absenceDetails, getAbsenceDetails, publicHolidaysCollection, setStateRequestForm, activeDate, setActiveDate, getDaysBetweenDateAndToday } = useAbsenceAPI();
    const [filters, setFilters] = useState(["time_off", "sick_leave", "special_leave", "validate", "confirm", "refuse"]);
    const { t, i18n } = useTranslation();
    moment.locale(i18n.language);

    function returnPublicHolidayDetails(date) {
        return publicHolidaysCollection.find(holiday => holiday.date === date);
    }

    const handleMouseEnterDate = (e) => {
        absenceDetails?.id !== Number(e.target.dataset.requestId) && getAbsenceDetails(Number(e.target.dataset.requestId));

        const overlay = document.querySelector(`#overlay-${e.target.dataset.date}`);
        overlay?.classList?.remove("d-none");
    };

    const handleMouseLeaveDate = (e) => {
        const overlay = document.querySelector(`#overlay-${e.target.dataset.date}`);
        overlay?.classList?.add("d-none");
    };

    const renderAbsenceOverlay = (date) => {
        const publicHolidayDetails = returnPublicHolidayDetails(date);

        return (
            <div className="calendar-content__overlay d-none" id={`overlay-${date}`} key={`overlay-${date}`}>
                {publicHolidayDetails && (
                    <div className="absence-card public_holiday validate">
                        <div className="absence-card__header_text absence-card__header_text--small">
                            <div className="absence-card__category--small">{publicHolidayDetails.name}</div>
                            <div className="absence-card__time_period">{t("TooltipCalendar.public_holiday_on")} {moment(date).format("DD. MMM")}</div>
                        </div>
                    </div>
                )}
                {absenceDetails?.id && <AbsenceCard request={absenceDetails} size="small" />}
            </div>
        );
    };

    const renderCalendarDay = (date, startOfMonth, isSingleMonth) => {
        let currentDate = date.format("YYYY-MM-DD");
        const isCurrentMonth = date.isSame(startOfMonth, 'month');
        const isToday = date.isSame(moment(), 'day');
        const isPublicHoliday = returnPublicHolidayDetails(currentDate);
        const isWithinSevenDays = getDaysBetweenDateAndToday(date) >= -7;
        const shouldRenderAbsenceOverlay = (isSingleMonth || isCurrentMonth) && window.screen.width >= 1200;

        const handleClickDay = (e) => {
            const requestId = Number(e.target.dataset.requestId) || undefined;
            const displayRequestForm = requestId || (isWithinSevenDays && !returnPublicHolidayDetails(e.target.dataset.date));
            if (requestId && absenceDetails?.id !== requestId) {
                getAbsenceDetails(requestId);
            }
            if (!requestId){
                getAbsenceDetails(undefined);
            }
            setStateRequestForm({ display: displayRequestForm, id: requestId, view: requestId ? "read" : "create", startDate: e.target.dataset.date });
        };

        return (
            <div
                className={`calendar-content__day ${isCurrentMonth ? "calendar-content__day--active" : "calendar-content__day--inactive"} ${isPublicHoliday ? "calendar-content__public_holiday" : ""} ${isToday ? "today" : ""}`}
                title={absenceDetails ? "" : moment(currentDate).format("dddd, DD. MMM")}
                style={isWithinSevenDays ? { cursor: "pointer" } : { cursor: "default" }}
                id={`date-${currentDate}`}
                key={`date-${currentDate}`}
                data-date={currentDate}
                data-request-id={undefined}
                onClick={handleClickDay}
                onTouchEnd={handleClickDay}
                onMouseEnter={handleMouseEnterDate}
                onMouseLeave={handleMouseLeaveDate}
            >
                {date.date()}
                {shouldRenderAbsenceOverlay && renderAbsenceOverlay(currentDate)}
            </div>
        );
    };

    const getCalendarMonthContent = (date, isSingleMonth) => {
        const startOfMonth = moment(date).startOf('month');
        const startOfWeek = moment(date).startOf('month').startOf('isoWeek');
        const endOfWeek = moment(date).endOf('month').endOf('isoWeek');

        const weeks = [];
        let currentDate = startOfWeek;

        while (currentDate.isBefore(endOfWeek)) {
            const daysInWeek = [];

            for (let day = 0; day < 7; day++) {
                daysInWeek.push(renderCalendarDay(currentDate, startOfMonth, isSingleMonth));
                currentDate.add(1, "day");
            }

            weeks.push(
                <div className="calendar-content__week" key={`week-${currentDate.isoWeek()}`}>
                    {daysInWeek}
                    {window.screen.width < 1200 && daysInWeek.map(day => renderAbsenceOverlay(day.props['data-date']))}
                </div>
            );
        }

        return <div className="calendar-content__week-container" key={startOfMonth}>{weeks}</div>;
    };

    function getAllWeekdaysShort() {
        return Array.from({ length: 7 }, (_, i) =>
            moment().startOf('isoweek').add(i, 'day').format('dd')
        );
    }

    const markAbsencesInCalendar = useCallback((selectedAbsences, isSingleMonth) => {
        const selectDate = (dateString) => {
            return isSingleMonth ? document.querySelector(`#date-${dateString}`) : document.querySelector(`.calendar-content__day--active#date-${dateString}`);
        };

        selectedAbsences?.forEach(({ id, date_from, date_to, time_off_main_category, state }) => {
            const startDate = moment(date_from).startOf("day");
            const endDate = moment(date_to).startOf("day");

            const startSelector = selectDate(startDate.format("YYYY-MM-DD"));
            startSelector?.classList.add(endDate > startDate ? "start" : "single");

            const endSelector = selectDate(endDate.format("YYYY-MM-DD"));
            endDate > startDate && endSelector?.classList.add("end");

            let currentDate = startDate;
            while (currentDate <= endDate) {
                const dateSelector = selectDate(currentDate.format("YYYY-MM-DD"));
                if (dateSelector) {
                    const isFiltered = filters.includes(state) && (!time_off_main_category || filters.includes(time_off_main_category));
                    const hasRowStarted = currentDate.day() === 1 || (!isSingleMonth && currentDate.date() === moment(currentDate).startOf('month').date());
                    const hasRowEnded = currentDate.day() === 0 || (!isSingleMonth && currentDate.date() === moment(currentDate).endOf('month').date());

                    isFiltered ?
                        dateSelector.classList.add("calendar-content__absence", state, time_off_main_category || "uncategorized", hasRowStarted ? "row-start" : hasRowEnded && "row-end")
                        : dateSelector.classList.remove("calendar-content__absence", state, time_off_main_category || "uncategorized", "start", "end", "single", "row-start", "row-end", "false")

                    isFiltered && id ?
                        dateSelector.dataset.requestId = id
                        : dateSelector.removeAttribute('data-request-id');
                }

                currentDate.add(1, 'days');
            }
        });
    }, [filters])

    const YearSelect = () => {
        return (
            <div className="select-year">
                {activeDate.year() > moment().subtract(1, "year").year() ?
                    <button className="select-year__button--active" onClick={() => setActiveDate(moment(activeDate).subtract(1, "year"))}>
                        <img src={process.env.PUBLIC_URL + "/images/icon-btn-back.svg"} alt="" />
                    </button>
                    : <div className="select-year__button--inactive">
                        <img src={process.env.PUBLIC_URL + "/images/icon-btn-back.svg"} alt="" className="disabled" />
                    </div>
                }
                <div style={{ width: "64px" }}>
                    {activeDate.year()}
                </div>
                {activeDate.year() < moment().add(1, "year").year() ?
                    <button className="select-year__button--active" onClick={() => setActiveDate(moment(activeDate).add(1, "year"))}>
                        <img src={process.env.PUBLIC_URL + "/images/icon-btn-back.svg"} alt="" className="mirrored" />
                    </button>
                    : <div className="select-year__button--inactive">
                        <img src={process.env.PUBLIC_URL + "/images/icon-btn-back.svg"} alt="" className="mirrored disabled" />
                    </div>
                }
            </div>
        );
    };

    return (
        <calendarContext.Provider value={{ filters, setFilters, getCalendarMonthContent, getAllWeekdaysShort, markAbsencesInCalendar, YearSelect }}>
            {children}
        </calendarContext.Provider>
    )
}

export function useCalendar() {
    return useContext(calendarContext)
}