import { PublicClientApplication, EventType, InteractionRequiredAuthError, LogLevel } from "@azure/msal-browser";
import axios from 'axios';
import { logToDatadog } from '../index';

export const msalInstance = new PublicClientApplication({
  auth: {
    clientId: process.env.REACT_APP_AZURE_CLIENT_ID,
    authority: process.env.REACT_APP_AZURE_AUTHORITY,
    navigateToLoginRequestUrl: false,
    postLogoutRedirectUri: window.location.origin
  },
  cache: {
    cacheLocation: "localStorage",
  },
  system: {
    allowRedirectInIframe: true,
    loggerOptions: {
      loggerCallback: (level, message, containsPii) => {
          if (containsPii) {
              return;
          }
          switch (level) {
              case LogLevel.Error:
                  logToDatadog(message, "error");
                  return;
              case LogLevel.Info:
                  logToDatadog(message, "info");
                  return;
              case LogLevel.Verbose:
                  console.debug(message);
                  return;
              case LogLevel.Warning:
                  logToDatadog(message, "warn");
                  return;
              default:
                  return;
          }
      },
    },
  },
})

msalInstance.addEventCallback((event) => {
  if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {
    const account = event.payload.account;
    msalInstance.setActiveAccount(account);
  }
}, error => console.error(error));

let accessTokenFetched = false;

export async function fetchAccessToken() {
  if (accessTokenFetched) {
    logToDatadog("fetchAccessToken: Token already fetched. Using existing token.");
    return;
  }
  // Logger user info.
  if (isMobileDevice()) {
    logToDatadog("fetchAccessToken: User is fetching token from Mobile device");
  } else {
    logToDatadog("fetchAccessToken: User is fetching token from Desktop device");
  }
  logToDatadog(`fetchAccessToken: User fetching token from URL and set redirectUri to: ${window.location.origin}`);
  const tokenRequest = {
    scopes: [`${process.env.REACT_APP_AZURE_CLIENT_ID}/.default`],
    redirectUri: window.location.origin,
    account: msalInstance.getActiveAccount() || msalInstance.getAllAccounts()[0]
  };
  function setRequestHeaders(tokenResponse) {
    axios.defaults.headers.common['authorization'] = `Bearer ${tokenResponse.accessToken}`;
    axios.defaults.headers.common['accept-language'] = `${window.localStorage.i18nextLng}`;
  }
  try {
    const tokenResponse = await msalInstance.acquireTokenSilent(tokenRequest);
    setRequestHeaders(tokenResponse);
  } catch (acquireTokenSilentError) {
    // Needs to provide credentials again
    if (acquireTokenSilentError instanceof InteractionRequiredAuthError || acquireTokenSilentError.message.includes('post_request_failed')) {
      try {
        const tokenResponse = await msalInstance.acquireTokenRedirect(tokenRequest);
        setRequestHeaders(tokenResponse);
      } catch (acquireTokenRedirectError) {
          if (acquireTokenRedirectError.message.includes('redirect_in_iframe')) {
            try {
              const tokenResponse = await msalInstance.acquireTokenPopup(tokenRequest);
              setRequestHeaders(tokenResponse);
            } catch (acquireTokenPopupError) {
              if (acquireTokenPopupError.message.includes('popup_window_error')) {
                if (isMobileDevice()){
                  logToDatadog(`fetchAccessToken: popup_window_error for mobile device: ${acquireTokenPopupError.message} | redirectUri ${window.location.origin}`, "error");
                } else {
                  logToDatadog(`fetchAccessToken: popup_window_error in other places: ${acquireTokenPopupError.message} | redirectUri ${window.location.origin}`, "error");
                }
              } else {
                logToDatadog(`fetchAccessToken: Error acquiring access token method TokenPopup: ${acquireTokenPopupError.message} | redirectUri ${window.location.origin}`, "error");
              }
            }
          } else {
            logToDatadog(`fetchAccessToken: Error acquiring access token method TokenRedirect: ${acquireTokenRedirectError.message} | redirectUri ${window.location.origin}`, "error");
          }
      }
    } else {
      logToDatadog(`fetchAccessToken: Error acquiring access token method TokenSilent: ${acquireTokenSilentError.message} | redirectUri ${window.location.origin}`, "error");
    }
    
}

  accessTokenFetched = true;
}

const isMobileDevice = () => {
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
}
