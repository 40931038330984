import React, { useState, useEffect, useMemo } from 'react'
import { useAbsenceAPI } from '../../contexts/AbsencesContext';
import { Spinner } from 'react-bootstrap';
import lifecycle from 'page-lifecycle';
import moment from "moment";

const Notifications = ({ t }) => {
    const { absencesData, getAbsenceDetails } = useAbsenceAPI();
    const [isLoading, setIsLoading] = useState(true);
    let notifications = useMemo(() => [], [])

    useEffect(() => {
        const onVisibilityChange = (e) => {
            e.preventDefault()
            e.returnValue = 'return something'
            localStorage.setItem("last_active", new Date());
        }
        lifecycle.addEventListener("statechange", onVisibilityChange)
        return () => lifecycle.removeEventListener("statechange", onVisibilityChange)
    }, [])

    useEffect(() => {
        if (notifications.length === 0) {
            absencesData.forEach((request) => {
            let daysUntilToday = Math.floor((new Date() - new Date(request.date_from)) / (1000 * 60 * 60 * 24));
            if ((daysUntilToday <= 7 || new Date(new Date().toDateString()) <= new Date(new Date(request.date_to).toDateString()))) {
                if (new Date(request.write_date) > localStorage.getItem("last_active")) {
                    getAbsenceDetails(request.id).then(details => {
                        const history = details ? details.modification_history : undefined;
                        if (history) {
                            history.forEach((el, key) => { 
                            if (new Date(el.create_date) > localStorage.getItem("last_active")) {
                                notifications.push({
                                    message: (el.state === "To Approve" || el.state === "Zur Genehmigung") ? t("Message.request_to_approve")
                                        : (el.state === "Approved" || el.state === "Genehmigt") ? `${el.name} ${t("Message.request_approved")}`
                                            : (el.state === "Refused" || el.state === "Verworfen") ? `${el.name} ${t("Message.request_refused")}`
                                                : el.state,
                                    state: el.state,
                                    create_date: el.create_date,
                                    key: key
                                })
                            }
                        })}
                    })
                }
            }
            setIsLoading(false)
            });
        }
    }, [absencesData, getAbsenceDetails, notifications, t]);

    const NotificationList = () => {
        return notifications.map((notification) => {
            return (
                <div className="request-form__modification_history_item" key={notification.key}>
                    <img alt="" src={(notification.state === "To Approve" || notification.state === "Zur Genehmigung") ? process.env.PUBLIC_URL + "/images/icon-pending.svg"
                        : (notification.state === "Approved" || notification.state === "Genehmigt") ? process.env.PUBLIC_URL + "/images/icon-approved.svg"
                            : (notification.state === "Refused" || notification.state === "Verworfen") && process.env.PUBLIC_URL + "/images/icon-refused.svg"
                    } />
                    <div className="d-flex flex-column justify-content-center h-100" style={{ lineHeight: "140%", fontSize: "13px", padding: "0px 15px", gap: "4px" }}>
                        {notification.message}
                        <div className="absence-card__write_date">{moment.utc(notification.create_date).fromNow()}</div>
                    </div>
                </div>
            )
        }, [])
    }

    return (
        <div className="page-container bg-feed view box w-100" style={{ fontSize: "14px" }}>
            {isLoading ?
                <Spinner size="md" />
                : <NotificationList />
            }
        </div>
    )
}

export default Notifications