export const TRANSLATIONS_DE = {
    Alert: {
        critical_error: "Es ist folgender Fehler aufgetreten, wende dich bitte an den Support: ",
        refresh_page: "Es können keine Daten vom Dienst abgerufen werden. Bitte aktualisieren Sie die Seite",
        problem_repeats: "Sollte das Problem erneut auftreten, melden Sie sich bitte ab und versuchen Sie es erneut.",
        error_select_required_fields: "Bitte wähle die Art sowie das Start- und Enddatum der Abwesenheit aus.",
        success_message_time_off_paid: "Dein Antrag auf Urlaub wurde erfolgreich gesendet!",
        success_message_sick_leave: "Bitte informiere auch zuständige Teamleader und Project Planner bzw. bei Bedarf direkte Teammitglieder über deine Abwesenheit. Gute Besserung!",
        success_message_update: "Dein Abwesenheitsantrag wurde aktualisiert.",
        success_message_refusal: "Dein Urlaubsantrag wurde zurückgezogen.",
        success_message_deletion: "Dein ausstehender Urlaubsantrag wurde gelöscht.",
        sick_note_uploaded: "Deine Krankmeldung wurde erfolgreich hochgeladen."
    },
    Home: {
        welcome: "Willkommen 👋",
        last_requests: "Letzte Anträge",
        absences: "Abwesenheiten",
        year_overview: "Jahresübersicht"
    },
    FAQ: {
        heading: "Kurzeinstieg in die wichtigsten Funktionen der App",
        video_url: "https://gmsholding.sharepoint.com/sites/Personal/_layouts/15/embed.aspx?UniqueId=406a397e-d16e-49e4-ad1b-34d3a9297c76&embed=%7B%22af%22%3Atrue%2C%22hvm%22%3Atrue%2C%22ust%22%3Atrue%7D&referrer=StreamWebApp&referrerScenario=EmbedDialog.Create"
    },
    MainMenu: {
        home: "Home",
        faq: "Hilfe / FAQ",
        language: "Sprache",
        email_support: "E-Mail Support",
        logout: "Abmelden"
    },
    Category: {
        categories: "Kategorien",
        time_off: "Urlaub",
        sick_leave: "Krankheit",
        special_leave: "Sonderurlaub",
        public_holiday: "Feiertag",
    },
    State: {
        states: "Status",
        approved: "Genehmigt",
        pending: "Ausstehend",
        refused: "Abgelehnt",
    },
    Stats: {
        used: "Genutzt",
        available: "Verbleibend",
        pending: "Eingereicht",
        remaining_previous_year: "Resturlaub",
        sick_days: "Krankheit"
    },
    TooltipStats: {
        available: "Urlaubstage die noch frei beantragt werden können - offene Anträge sind bereits abgezogen",
        used: "Bereits genommene oder freigegebene Urlaubstage",
        pending: "Anträge, die derzeit auf die Freigabe des Vorgesetzten warten",
        remaining_previous_year: "Verbleibender Urlaubsanspruch vom Vorjahr.",
        sick_days: "Krankheitstage im laufenden Jahr"
    },
    RequestList: {
        next_absences: "Nächste Abwesenheiten",
        all_absences: "Alle Abwesenheiten",
        view_all: "Alle anzeigen",
        collapse: "Weniger anzeigen",
        no_absences_available: "Keine Abwesenheiten vorhanden",
        no_absences_planned_in_near_future: "Keine Abwesenheiten in naher Zukunft geplant",
        stats_summary: "Zusammenfassung"
    },
    RequestForm: {
        half_day: "Halber Tag",
        am: "Vormittag",
        pm: "Nachmittag",
        upload_sick_note: "Krankschreibung anhängen",
        electronic_certificate: "Elektronische Krankschreibung",
        first_day_of_incapacity: "Erster Tag der ärztl. Krankschreibung",
        add_comment: "Bemerkung hinzufügen",
        placeholder_category_select: "Art der Abwesenheit"
    },
    Button: {
        back: "Zurück",
        request: "Antrag stellen",
        cancel: "Antrag stornieren",
        delete: "Antrag löschen",
        submit: "Antrag einreichen",
        update: "Update senden",
        view: "Details",
        edit: "Bearb."
    },
    RequestDate: {
        single: "Datum der Abwesenheit",
        start: "Beginn Abwesenheit",
        end: "Ende Abwesenheit",
        of: "des",
        to: "bis",
        to_the: "bis zum",
    },
    ModalRefuse: {
        comment_placeholder: "Warum möchtest du den Abwesenheitsantrag zurückziehen?",
        confirm_refusal: "Möchtest du den Abwesenheitsantrag wirklich zurückziehen? Dies kann nicht rückgängig gemacht werden.",
        cancel: "Abbrechen",
        refuse: "Ja, Antrag zurückziehen"
    },
    Message: {
        request_to_approve: "Dein Antrag wurde zur Genehmigung eingereicht.",
        request_approved: "hat deinen Urlaubsantrag genehmigt.",
        request_refused: "hat deinen Urlaubsantrag abgelehnt.",
        request_withdrawn: "Du hast deinen Urlaubsantrag zurückgezogen.",
        sick_leave_submitted: "Deine Krankmeldung wurde eingereicht."
    },
    CategoryNote: {
        sick_leave_work_accident: "Wichtig! Der Arbeitsunfall muss außerdem über dieses Formular gemeldet werden:",
        sick_leave_work_accident_url: "https://unfallmeldung.crmz.org"
    },
    TooltipCalendar: {
        public_holiday_on: "Feiertag am"
    }
};