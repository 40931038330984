import React, { useEffect } from 'react';
import * as microsoftTeams from "@microsoft/teams-js";
import { msalInstance } from '../config/MsalConfig';

/**
 * This component is used to redirect the user to the Azure authorization endpoint from a popup.
 */
function TeamsClosePopup() {

    useEffect(() => {
        microsoftTeams.app.initialize().then(() => {
            microsoftTeams.app.getContext().then(async (context) => {
                msalInstance.handleRedirectPromise()
                    .then((tokenResponse) => {
                        if (tokenResponse !== null) {
                            microsoftTeams.authentication.notifySuccess("Authentication succeeded");
                        } else {
                            microsoftTeams.authentication.notifyFailure("Get empty response.");
                        }
                    })
                    .catch((error) => {
                        microsoftTeams.authentication.notifyFailure(JSON.stringify(error));
                    });
            });
        });
    }, [])

    return (
        <div>
            <h3>Consent flow complete.</h3>
        </div>
    );
}

export default TeamsClosePopup;