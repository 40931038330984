import React, { useEffect } from "react";
import { useAbsenceAPI } from "../contexts/AbsencesContext";
import { useCalendar } from "../contexts/CalendarContext";
import moment from "moment";
import '../styles/calendar.scss';

const MonthCalendar = () => {
    const { absencesSelectedMonth, activeDate, setActiveDate } = useAbsenceAPI();
    const { getCalendarMonthContent, getAllWeekdaysShort, markAbsencesInCalendar, YearSelect } = useCalendar();

    const weekdaysShort = getAllWeekdaysShort();

    useEffect(() => {
        absencesSelectedMonth?.length > 0 && markAbsencesInCalendar(absencesSelectedMonth, true);
    }, [markAbsencesInCalendar, absencesSelectedMonth]);

    return (
        <div className="month-calendar-container default-box">
            <div className="month-calendar-content">
                <div className="d-flex justify-content-between">
                    <YearSelect />
                    <select className="form-select selectedMonth" value={activeDate.month()} onChange={(e) => setActiveDate(moment(activeDate).month(e.target.value))}>
                        {moment.months().map((month, i) => (
                            <option key={i} value={i}>
                                {month}
                            </option>
                        ))}
                    </select>
                </div>
                <div className="d-flex flex-column" style={{ gap: "17px" }}>
                    <div className="calendar__weekdays-row">
                        {weekdaysShort.map((weekdayShort, index) => (
                            <div className="calendar__weekday-short" key={index}>{weekdayShort}</div>
                        ))}
                    </div>
                    {getCalendarMonthContent(activeDate, true)}
                </div>
            </div>
            <div className="month-calendar-container__nav-buttons btn-group">
                <button className="month-calendar-container__btn-nav btn" id="btn-prev" onClick={() => setActiveDate(moment(activeDate).subtract(1, "month"))}>
                    <img src={process.env.PUBLIC_URL + "/images/icon-btn-back.svg"} alt="" />
                </button>
                <button className="month-calendar-container__btn-nav btn" id="btn-next" onClick={() => setActiveDate(moment(activeDate).add(1, "month"))} >
                    <img src={process.env.PUBLIC_URL + "/images/icon-btn-back.svg"} alt="" className="mirrored" />
                </button>
            </div>
        </div>
    );
};

export default MonthCalendar;