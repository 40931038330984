export const FAQ_EN = {
    Q1: {
        question: 'Statistics, Next Absences and Calendar',
        answer_1: 'You will find the statistics for the current year in the upper section of the screen. If you have any remaining vacation days, they will also be displayed in the statistics. As a user of the mobile application, you can view the statistics by opening the menu through a click on your profile picture.',
        answer_2: 'In addition, the three most recent absences are displayed, giving you a good overview of your current sick days and vacation time. Clicking on „Show all“ will expand the list for the entire year.',
        answer_3: 'The small calendar allows you to quickly overview individual months. Here, you can directly click on absences to view all details or make any necessary changes. Below the small calendar, you have the option to access the yearly overview and get a full overview of the entire year.'
    },
    Q2: {
        question: 'Requesting Vacation',
        answer_1: 'To submit a new vacation request, click on the green button „Request“. A form will open where you can provide details about your vacation request.',
        answer_2: 'First, select „Holiday“ as the type of absence. Next, you can specify the start and end dates. By clicking on the text field, a calendar will open up where you can select the desired time period. After making your selection, confirm the time period by clicking the button at the bottom right.',
        answer_3: 'If you want to take a half day off, activate the slider for „Half day“. Now, you can select the corresponding day and specify whether the absence is in the morning or in the afternoon.',
        answer_4: 'If you have a note regarding your vacation request, you can enter it in the comment field.',
        answer_5: 'Finally, click on „Submit Request“. Your request will be sent directly to your supervisor in the system and can be approved by them. You will be notified by email once the request is approved, and you can then add it to your Outlook calendar.'
    },
    Q3: {
        question: 'Changing Vacation',
        answer_1: 'Existing vacation requests can be freely modified in the system as long as they have not yet been approved. Here, you can update all the information in the system. To do so, open the corresponding vacation request by clicking on „Details“ in the list.',
        answer_2: 'If your vacation request has already been approved, you can remove it from the system up to seven days before the start of your vacation by clicking on „Cancel Request“ in the detail view. Please note that the vacation will be immediately removed from the system when you cancel the request. If you want to make changes to already approved vacation, please contact your supervisor so that they can take these changes into account accordingly.',
    },
    Q4: {
        question: 'Reporting and Updating Sick Leave',
        answer_1: 'If you are unable to come to work due to illness, it is important that we receive this information as soon as possible. You can report your inability to work due to illness via the app.',
        answer_2: 'To do so, click on the „Submit request“ button to open the form. Select the corresponding type and then submit the form.',
        answer_3: 'If you have received a medical certificate from your doctor, you can attach it either in paper form or as an electronic certificate.'
    },
    Q5: {
        question: 'Updating Sick Leave',
        answer_1: 'It may happen that you are sick for a longer period than originally reported. In this case, you can update an already active sick leave and provide a new estimated end date. In addition, you can send additional information to your supervisor through the comment field.',
        answer_2: 'During the update, you have the option to update the following information: end date, comments, and medical certificate.',
        answer_3: 'For all other cases, please contact your HR manager.'
    },
    Q6: {
        question: 'Help, something isn`t working.',
        answer: 'If there are any issues or something is not working as expected, your HR manager can usually help you. If you think that there are technical issues, please use the „Feedback“ button on the right-hand side. This will allow you to send a screenshot to the ticketing system, and we will work to find a solution as soon as possible.'
    }
}